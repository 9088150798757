export const environment = {
  production: false,
  apiUrlConfigs: 'https://iothub-pre-prod.syensqo.com/api/v1/configuration/',
  apiUrlCommands: 'https://iothub-pre-prod.syensqo.com/api/v1/command/',
  firebaseConfig: {
    apiKey: 'AIzaSyAAV9WySpHR_FNCPA1psYNuEB6csAmayNM',
    authDomain: 'gcp-sqo-iothub-u.firebaseapp.com',
    databaseURL: 'https://gcp-sqo-iothub-u.firebaseio.com',
    projectId: 'gcp-sqo-iothub-u',
    storageBucket: 'gcp-sqo-iothub-u.firebasestorage.app',
    messagingSenderId: '184907500933',
    appId: '1:184907500933:web:fa255f6ead1e74cd1808ec',
  },
  googleSheetBaseUrl: 'https://docs.google.com/spreadsheets/d/',
  supportGoogleSheetUrl:
    'https://docs.google.com/spreadsheets/d/1DRYYBYffdZgB-Xu9KO9tofLT6UtB1vil05LFbFo7-KM/edit?gid=0',
  gsExporterServiceAccount: 'gcp-sqo-iothub-u@appspot.gserviceaccount.com',
  openStreetMap: {
    defaultMapCenter: {
      lat: 50.901072,
      lng: 4.400331,
    },
    layerTilesUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  serviceAccountGoogleSheet: 'gcp-sqo-iothub-u@appspot.gserviceaccount.com',
  stage: 'u',
};
